














































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import store from '@/services/store';
import { formLst } from '@/modules/budget-request/components/js/budgetFormsLst';
import { variantNameLangSupport } from '@/modules/budget/budgetCorrectHelper';

@Component({
    name: 'c-budget-req-head',
    components: {
        'multiselect': Multiselect
    }
})
export default class CBudgHeadReq extends Vue {
    @Prop({
        required: false,
        default: true
    })
    private typeReq!: boolean;

    @Prop({
        required: false,
        default: null
    })
    private form!: any | null;

    @Prop({
        required: false,
        default: true
    })
    private guReq!: boolean;

    @Prop({
        required: false,
        default: 'Год'
    })
    private nameYear!: string;

    @Prop({
        required: false,
        default: null
    })
    private yearReq!: boolean; // добавление параметр год year (на выходе yearProp)

    @Prop({
        required: false,
        default: null
    })
    private filter!: any | null;

    @Prop({
        required: false,
        default: null
    })
    private specificProp!: any[] | null; // список специфик (для произвольных форм)

    @Prop({
        required: false,
        default: null
    })
    private defaultProps!: any| null; // параметры по умолчанию

    @Prop({
        required: false,
        default: null
    })
    private developType!: number| null; // developType для программ (Азамату)

    @Prop({
        required: false,
        default: false
    })
    private openDisabled!: boolean; // disabled кнопка открыть

    @Prop({
        required: false,
        default: 0
    })
    private progDevelopType!: number | null; // develop_type для программ (по умолчанию = 0)

    private urlFilter: any = null;

    public openFilterByRef(refName: any) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    private get filterMap() {
        if (this.filter === null) { return null; }
        const result = new Map();
        for (const el of this.filter) {
            const abpMap = new Map();
            const abpArr = [];
            for (const abpEl of el.abp) {
                const progMap = new Map();
                if (abpEl.progr !== undefined && abpEl.progr !== null) {
                    for (const progEl of abpEl.progr) {
                        progMap.set(progEl.prg, progEl);
                    }
                }
                const abpResult = Object.assign({ progr: progMap }, abpEl);
                abpMap.set(abpEl.abp, abpResult);
                const abpClear = Object.assign({}, abpEl);
                delete abpClear.progr;
                abpArr.push(abpClear);
            }
            result.set(el.funcGr, { abpMap: abpMap, abp: abpArr });
        }
        return result;
    }

    private budgetLevel: number[] = [];

    private obl: string | null = null;
    private region: any | null = null;

    private curForm: any = null;
    private curFormSelect: any = null;

    private get formDict() {
        const result: any[] = [];
        for (let i = 0; i < formLst.length; i++) {
            const obj = this.setNameLang(formLst[i]);
            obj.id = i;
            result.push(obj);
        }
        return result;
    }

    /* private formDict: any[] = [];
    private curForm: any | null = null; */

    private specific: any | null = null; // спецификация

    // --------- гос учреждение ------------
    private companyBase: any[] = [];
    private curComp: any | null = null;

    private get company(): any[] {
        const result: any[] = [];
        for (const el of this.companyBase) {
            result.push(this.setNameLang(el, 'code'));
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        return result;
    }
    // ------------вид данных-----------
    private dataTypeDictBase: any[] = [];
    private curDataType: any | null = null;

    private get dataTypeDict(): any[] {
        const result: any[] = [];
        for (const el of this.dataTypeDictBase) {
            result.push(this.setNameLang(el, 'code'));
        }
        if (this.curDataType !== null) { this.curDataType = this.setNameLang(this.curDataType, 'code'); }
        return result;
    }
    // ------------версия бюджета-----------
    private budgetVersionDictBase: any[] = [];
    private curBudgetVersion: any | null = null;

    private get variantLst() {
        const res: any[] = [];
        for (const el of this.budgetVersionDictBase) {
            res.push(this.setCodeText(el, 'variant_uuid'));
        }
        if (this.curBudgetVersion !== null) { this.curBudgetVersion = this.setNameLang(this.curBudgetVersion); }
        return res;
    }

    // -------------------------------

    private curYear = null;
    private year: number | null = null;
    private maxYear = 3000;
    private minYear = 2000;

    private periodLst: any[] = [];
    private curPeriod: any | null = null;

    private getPeriodLst() {
        const year = (new Date()).getFullYear();
        this.periodLst = [];
        for (let i = year - 2020 + 1; i > 0; i--) {
            this.periodLst.push({ name: `${2020 + i} - ${2020 + i + 2}`, year: 2020 + i });
        }
        this.curPeriod = { name: `${year + 1} - ${year + 3}`, year: year + 1 };
        this.curYear = this.curPeriod.year;
        this.year = year + 1;
        this.maxYear = year + 3;
        this.minYear = year + 1;
    }

    // ---------------- функциональная группа ------------------
    private funcGrBase: any[] = [];
    private curFunGr: any | null = null;

    private get funcGr(): any[] {
        const res = [];
        for (const el of this.funcGrBase) {
            res.push(this.setNameLang(el, 'gr'));
        }
        if (this.curFunGr !== null) { this.curFunGr = this.setNameLang(this.curFunGr, 'gr'); }
        res.sort((a, b) => (a.gr - b.gr > 0) ? 1 : -1);
        return res;
    }

    private async chgFuncGr() {
        await this.loadAbp();
    }


    // ------------------ администратор программ ------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }

        if (this.curAbp !== null) { this.curAbp = this.setNameLang(this.curAbp, 'abp'); }
        return res;
    }

    private async chgAbp() {
        await this.loadProg();
        await this.loadCompany();
    }

    // ------------------------ программа --------------------------
    private progBase: any[] = [];
    private curProg: any | null = null;

    private get prog(): any[] {
        const res: any[] = [];
        for (const el of this.progBase) {
            res.push(this.setNameLang(el, 'prg'));
        }
        if (this.curProg !== null) { this.curProg = this.setNameLang(this.curProg, 'prg'); }
        return res;
    }

    private async chgProg() {
        await this.loadSubProg();
    }
    // ----------------------------подпрограмма------------------------
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        if (this.curSubProg !== null) { this.curSubProg = this.setNameLang(this.curSubProg, 'ppr'); }
        return res;
    }
    // ----------------------------- load -----------------------------------

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({ idArr: [el.id] }, el));
            }
        }
        return result;
    }

    private async loadFuncGR() {
        let response: any = [];
        this.funcGrBase = [];
        if (this.usrId === null) { return; }
        try {
            response = await fetch(`/api-py/get-func-gr-by-user-id/${this.usrId}`);
            this.funcGrBase = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки фун. групп', (error as Error).toString());
            response = [];
        }
        if (this.funcGrBase.length > 0) {
            if (this.urlFilter && this.urlFilter.gr) {
                this.curFunGr = this.findElByCode('gr', this.urlFilter.gr, this.funcGrBase);
                this.urlFilter.gr = null;
            } else {
                this.curFunGr = this.funcGrBase[0];
            }
            await this.chgFuncGr();
        }
    }

    private async loadAbp() {
        let response: any = [];
        this.abpBase = [];
        if (this.usrId === null) { return; }
        if (this.filterMap !== null) {
            const abp = this.filterMap.get(this.curFunGr.gr);
            if (abp !== undefined) { response = abp.abp; }
        }
        if (response.length === 0) {
            try {
                // response = await fetch('/api-py/get-dict-func-childs-by-parent-type/' + this.curFunGr.id + '/3/');
                response = await fetch(`/api-py/get-abp-by-func-gr-user-id/${this.curFunGr.gr}/${this.usrId}`);
                response = await response.json();
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки адм. программ', (error as Error).toString());
            }
        }
        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        this.abpBase = this.setIdArr(response, 'abp');

        if (this.abpBase.length > 0) {
            if (this.urlFilter && this.urlFilter.abp) {
                this.curAbp = this.findElByCode('abp', this.urlFilter.abp, this.abpBase);
                this.urlFilter.abp = null;
            } else {
                this.curAbp = this.abpBase[0];
            }
        } else {
            this.curAbp = null;
        }
        await this.chgAbp();
    }

    private async loadProg() {
        let response: any = [];
        this.progBase = [];
        if (this.filterMap !== null) {
            const abp = this.filterMap.get(this.curFunGr.gr);
            if (abp !== undefined) {
                const curAbp = abp.abpMap.get(this.curAbp.abp);
                if (curAbp !== undefined) {
                    response = curAbp.progr;
                }
            }
        }
        if (response.length === 0 && this.curAbp !== null) {
            try {
                response = await fetch('/api-py/get-dict-func-childs-by-parent-type/' + this.curAbp.id + '/4');
                // response = await fetch('/api-py/get-dict-func-childs-by-parent-type-budgetLevel/' + this.curAbp.id + '/4/' + encodeURI(JSON.stringify(this.budgetLevel)));
                response = await response.json();
                if (this.progDevelopType !== null) { response = response.filter((item: any) => item.develop_type === 0); }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки программ', (error as Error).toString());
            }
        }

        response.sort((a: any, b: any) => (a.prg - b.prg > 0) ? 1 : -1);
        if (this.developType !== null) {
            response = response.filter((item: any) => {
                return item.develop_type === this.developType;
            });
        }
        this.progBase = this.setIdArr(response, 'prg');

        if (this.progBase.length > 0) {
            if (this.urlFilter && this.urlFilter.prg) {
                this.curProg = this.findElByCode('prg', this.urlFilter.prg, this.progBase);
                this.urlFilter.prg = null;
            } else {
                this.curProg = this.progBase[0];
            }
        } else {
            this.curProg = null;
        }
        await this.chgProg();
    }

    private async loadSubProg() {
        let response: any = [];
        this.subProgBase = [];
        this.curSubProg = null;
        if (this.curProg !== null) {
            try {
                response = await fetch('/api-py/get-dict-func-childs-by-parent-type/' + this.curProg.id + '/5');
                // response = await fetch('/api-py/get-dict-func-childs-by-parent-type-budgetLevel/' + this.curProg.id + '/5/' + encodeURI(JSON.stringify([this.budgetLevel])));
                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки подпрограмм', (error as Error).toString());
                response = [];
            }
        }

        response.sort((a: any, b: any) => (a.ppr - b.ppr > 0) ? 1 : -1);
        this.subProgBase = this.setIdArr(response, 'ppr');

        if (this.subProgBase.length > 0) {
            if (this.urlFilter && this.urlFilter.ppr) {
                this.curSubProg = this.findElByCode('ppr', this.urlFilter.ppr, this.subProgBase);
                this.urlFilter.ppr = null;
            } else {
                this.curSubProg = this.subProgBase[0];
            }
        } else {
            this.curSubProg = null;
        }
    }

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

    private async loadBudgetLevel() {
        this.budgetLevel = [];
        if (!this.usrId) { return; }
        let result = [];
        try {
            result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId)).then(response => response.json());
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки уровня бюджета', (error as Error).toString());
            result = [];
            return;
        }
        if (!Array.isArray(result)) { return; }

        for (const el of result) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const indx = el.budget_level.lastIndexOf('_');
            if (indx >= 0) {
                this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
            }
        }
        if (this.budgetLevel.length === 0) { this.makeToast('warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!'); }
        await this.loadFuncGR();
    }
    // ----------------------------------

    private async created() {
        await this.getObl();
        this.getPeriodLst();
        if (this.defaultProps) {
            if (this.$route.params && this.$route.params.directLink == 'true'){
                this.getUrl();
            } else {
                this.getFormParams();
            }
        } else {
            this.getUrl();
        }
        // eslint-disable-next-line
        const that = this;
        if (this.form) {
            if (this.form.name) {
                this.curForm = Object.assign({}, this.form);
            } else {
                if (this.form.code) {
                    const tmp = { name: `Форма ${(this.form.code).trim()}`, code: this.form.code };
                    this.curForm = tmp;
                } else {
                    this.curForm = this.setNameLang(this.form);
                }
            }
            for (const el of this.formDict) {
                if (el.name === this.curForm.name) {
                    this.curFormSelect = el.id;
                }
            }
        }
        if (this.specificProp) {
            if (this.form && this.form.spf) {
                this.specific = this.form.spf;
            } else {
                this.specific = this.specificProp[0];
            }
        } else {
            await this.loadSpec();
        }
        this.$watch('curFormSelect', that.chgCurFormSelect);
        this.$watch('usrId', async () => { if (this.usrId) {
            await this.getObl();
            await this.loadBudgetLevel();
        } });
        await this.loadCompany();
        this.$watch('defaultProps', function () {
            if (this.$route.params.directLink !== 'true') {
                that.getFormParams();
                that.loadFuncGR();
            }
        });
        await this.loadBudgetLevel();
        await this.loadDataTypeDict();
        this.$watch('curPeriod', this.chgCurPeriod);
        this.chgData();
    }

    private async chgCurPeriod() {
        if (this.curPeriod) {
            this.curYear = this.curPeriod.year;
            this.year = this.curYear;
            this.maxYear = this.curPeriod.year + 2;
            this.minYear = this.curPeriod.year;
            if (this.curDataType && this.curDataType.code && this.region && this.region.code) {
                await this.loadBudgetVersionDict();
            }
        }
    }

    private getUrl() {
        const params = this.$route.params;
        if (params.curYear === undefined) {
            this.urlFilter = null;
            return;
        }
        this.urlFilter = {};
        if (params.curYear !== 'false') {
            this.urlFilter.year = null;
            if (params.curYear !== 'null') {
                this.urlFilter.year = parseInt(params.curYear);
                this.curYear = this.urlFilter.year;
                for (const el of this.periodLst) {
                    if (el.year === parseInt(this.urlFilter.year)) {
                        this.curPeriod = el;
                        this.chgCurPeriod();
                    }
                }
            }
        }
        if (params.year !== 'false') {
            this.urlFilter.yearProp = null;
            if (params.year !== 'null') {
                this.urlFilter.yearProp = parseInt(params.year);
                this.year = parseInt(this.urlFilter.yearProp);
            }
        }
        if (params.dataType !== 'false') {
            this.urlFilter.dataType = null;
            if (params.dataType !== 'null') {
                this.urlFilter.dataType = params.dataType;
            }
        }
        if (params.gr !== 'false') {
            this.urlFilter.gr = null;
            if (params.gr !== 'null') {
                this.urlFilter.gr = parseInt(params.gr);
            }
        }
        if (params.abp !== 'false') {
            this.urlFilter.abp = null;
            if (params.abp !== 'null') {
                this.urlFilter.abp = parseInt(params.abp);
            }
         }
        if (params.prg !== 'false') {
            this.urlFilter.prg = null;
            if (params.prg !== 'null') {
                this.urlFilter.prg = parseInt(params.prg);
            }
        }
        if (params.ppr !== 'false') {
            this.urlFilter.ppr = null;
            if (params.ppr !== 'null') {
                this.urlFilter.ppr = parseInt(params.ppr);
                this.curSubProg = { ppr: this.urlFilter.ppr };
            }
        }
        if (params.gu !== 'false') {
            this.urlFilter.gu = null;
            if (params.gu !== 'null') {
                this.urlFilter.gu = params.gu;
            }
        }
        if (params.variant_uuid !== 'false') {
            this.urlFilter.variant_uuid = null;
            if (params.variant_uuid !== 'null') {
                this.urlFilter.variant = params.variant_uuid;
            }
        }
    }

    // параметры defaultProps
    private getFormParams() {
        if (this.defaultProps) {
            this.urlFilter = {};
            if (this.defaultProps.dataType) { this.urlFilter.dataType = this.defaultProps.dataType; }
            if (this.defaultProps.gr) { this.urlFilter.gr = this.defaultProps.gr; }
            if (this.defaultProps.prg) { this.urlFilter.prg = this.defaultProps.prg; }
            if (this.defaultProps.ppr) { this.urlFilter.ppr = this.defaultProps.ppr; }
            if (this.defaultProps.abp) { this.urlFilter.abp = this.defaultProps.abp; }
            if (this.defaultProps.gu) { this.urlFilter.gu = this.defaultProps.gu; }
        }
    }

    private findElByCode(codeName: string, code: any, arr: any[]): any {
        if (code === null) { return null; }
        for (const el of arr) {
            if (el[codeName] === code) {
                return el;
            }
        }
        return null;
    }


    private async loadDataTypeDict() {
        let result: any[] = [];
        try {
            result = await fetch('/api-py/dict_budget_data_types/')
                .then(response => response.json());
        } catch (error) {
            result = [];
            this.makeToast('danger', 'Ошибка загрузки вида данных', (error as Error).toString());
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        this.dataTypeDictBase = result;
        if (this.dataTypeDictBase.length > 0) {
            let el = null;
            if (this.urlFilter && this.urlFilter.dataType) {
                el = this.findElByCode('code', this.urlFilter.dataType, this.dataTypeDictBase);
                this.urlFilter.dataType = null;
            } else {
                el = this.dataTypeDictBase[0];
            }
            this.curDataType = this.setNameLang(el);

            if (this.region && this.region.code) {
                await this.loadBudgetVersionDict();
            }
        }
    }


    private async chgDataType() {
        if (this.curDataType && this.curDataType.code && this.region && this.region.code) {
            await this.loadBudgetVersionDict();
        }
    }


    private async chgCurComp() {
        if (this.curDataType && this.curDataType.code && this.region && this.region.code) {
            await this.getRegionByCodeGu();
            await this.loadBudgetVersionDict();
        }
    }


    private async loadBudgetVersionDict() {
        let result: any[] = [];
        if (this.curComp.id_region === null) { return; }
        try {
            result = await fetch('/api-py/get-variants-by-data-type/' +  this.region.code + '/' + this.curDataType.code + '/' + this.curPeriod.year)
                .then(response => response.json());
            result = result.filter((item: any) => item.is_deleted === false);
        } catch (error) {
            result = [];
            this.makeToast('danger', 'Ошибка запроса loadBudgetVersionDict()', (error as Error).toString());
        }
        for (const el of result) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            el.name_ru = variantNameLangSupport(el, 'ru');
            // eslint-disable-next-line @typescript-eslint/camelcase
            el.name_kk = variantNameLangSupport(el, 'kk');
            // eslint-disable-next-line @typescript-eslint/camelcase
            el.name_en = variantNameLangSupport(el, 'en');
        }

        this.budgetVersionDictBase = result;
        if (this.budgetVersionDictBase.length === 0) {
            this.curBudgetVersion = null;
            return;
        }
        let el = null;
        if (this.urlFilter && this.urlFilter.variant) {
            el = this.findElByCode('variant_uuid', this.urlFilter.variant, this.budgetVersionDictBase);
            this.curBudgetVersion = el;
            this.urlFilter.variant = null;
            return;
        }
        if (this.curBudgetVersion === null) {
            this.curBudgetVersion = this.budgetVersionDictBase[0];
        } else {
            let fl = false;
            for (let i=0; i < this.budgetVersionDictBase.length; i++) {
                if (this.budgetVersionDictBase[i].variant_uuid === this.curBudgetVersion.variant_uuid) {
                    fl = true;
                    break
                }
            }
            if (!fl) { this.curBudgetVersion = this.budgetVersionDictBase[0]; }
        }
    }

    private async getObl() {
        if (!this.usrId) { return; }
        let region = this.region;
        try {
            const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);

            if (result.status === 200) {
                const json = await result.json();
                region = json.region;
                this.obl = json.obl;
            } else {
                this.makeToast('danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-obl', (error as Error).toString());
        }

        try {
            await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.usrId)
                .then(response => response.json())
                .then(json => {
                    if (json.length && json[0].code) { region = json[0].code; }
                });
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-user-budget-region', (error as Error).toString());
        }
        // this.region = region;
    }

    private async loadCompany() {
        let result: any[] = [];
        this.curComp = null;
        if (this.curAbp !== null) {
            try {
                result = await fetch(`/api-py/get-gu-by-abp-user-id/${this.curAbp.abp}/${this.usrId}`)
                    .then(response => response.json());
            } catch (error) {
                result = [];
                this.makeToast('danger', 'Ошибка загрузки гос. учреждений', (error as Error).toString());
            }
            if (result.length > 0) {
                if (this.urlFilter && this.urlFilter.gu) {
                    this.curComp = this.setNameLang(this.findElByCode('code', this.urlFilter.gu, result), 'code');
                    this.urlFilter.gu = null;
                    // this.chgData();
                } else {
                    this.curComp = this.setNameLang(result[0], 'code');
                }
                await this.getRegionByCodeGu();

                if (this.curDataType && this.curDataType.code) {
                    await this.loadBudgetVersionDict();
                }
            }
            this.companyBase = result;
        }
    }

    private async getRegionByCodeGu() {
        if (this.curComp && this.curComp.id_region) {
            let result: any[] = [];
            try {
                result = await fetch('/api-py/get-region-by-code-gu',
                    {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(this.curComp)
                    })
                    .then(response => response.json());
                if (result && result.length > 0 && result[0].code) {
                    this.region = result[0];
                }
            } catch (error) {
                result = [];
                this.makeToast('danger', 'Ошибка загрузки региона', (error as Error).toString());
            }
        }
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        if (obj === null) { return null; }
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private setCodeText(obj: any, codeName?: any | null): any {
        if (obj === null) { return null; }
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = txt + ' [' + obj[codeName] + ']';
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private async loadSpec() {
        let result: any[] = [];
        this.specific = null;
        if (this.form === null) { return; }
        if (this.form.spf !== undefined && this.form.spf !== null) {
            this.specific = this.form.spf;
            return;
        }
        let code = this.curForm.code;
        if (!code) { return; }
        code = code.substr(code.lastIndexOf('-') + 1);
        // this.curComp = null;
        try {
            result = await fetch('/api-py/dict-ebk-spf/' + code)
                .then(response => response.json());
        } catch (error) {
            result = [];
            this.makeToast('danger', 'Ошибка загрузки спецификации', (error as Error).toString());
        }
        if (result.length > 0) { this.specific = result[0]; }
        // this.chgData();
    }

    private saveData() {
        const saveObj: any = { form: this.curForm !== null ? this.curForm.code : null, year: this.curYear, yearProp: this.year, dataType: this.curDataType.code, gr: this.curFunGr.gr, abp: this.curAbp.abp, prg: this.curProg.prg };
        if (this.curSubProg !== null) { saveObj.pgr = this.curSubProg.pgr; } else { saveObj.pgr = null; }
        if (this.curComp !== null) { saveObj.gu = this.curComp.code; } else { saveObj.gu = null; }
        if (this.specific !== null) { saveObj.spf = this.specific.spf; } else { saveObj.spf = null; }
    }

    private chgData() {
        this.curYear = this.curPeriod.year;
        if (this.year !== null) {
            this.year = parseInt(this.year.toString());
        }
        if (!this.curSubProg && this.subProg && this.subProg.length > 0) {
            this.makeToast('danger', 'Внимание', 'Подпрограмма не выбрана');
        } else {
            if (this.form && !this.curBudgetVersion) {
                this.makeToast('warning', 'Предупреждение', 'Не найдена версия бюджета для выбранных параметров');
            } else {
                const data = { form: this.curForm, year: this.curYear, yearProp: this.year, dataType: this.curDataType, gr: this.curFunGr, abp: this.curAbp, prg: this.curProg, pgr: this.curSubProg, gu: this.curComp, spf: this.specific, region: this.region.code, budgetVersion: this.curBudgetVersion, guList: this.companyBase };
                this.$emit('chgData', data);
            }
        }
        const drop: any = this.$refs.drop;
        drop.hide(true);
    }

    private goToLink(item: any) {
        this.chgSpecific(item);
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private chgCurFormSelect() {
        if (this.curFormSelect === null) {
            this.curForm = null;
            return;
        }
        for (const el of this.formDict) {
            if (el.id === this.curFormSelect) {
                this.curForm = el;
                const data: any = { year: this.curYear, yearProp: this.year, dataType: false, gr: false, abp: false, prg: false, ppr: null, gu: false, variant: false };

                if (this.curDataType !== null) { data.dataType = this.curDataType.code; }
                if (this.curFunGr !== null) { data.gr = this.curFunGr.gr; }
                if (this.curAbp !== null) { data.abp = this.curAbp.abp; }
                if (this.curProg !== null) { data.prg = this.curProg.prg; }
                if (this.curSubProg !== null) { data.ppr = this.curSubProg.ppr; }
                if (this.curComp !== null && this.curComp.code !== null) { data.gu = this.curComp.code; }
                if (this.curBudgetVersion !== null) { data.variant = this.curBudgetVersion.variant_uuid }
                const params = `#${this.curForm.href}/${data.year}/${data.yearProp}/${data.dataType}/${data.gr}/${data.abp}/${data.prg}/${data.ppr}/${data.gu}/${data.variant}/true/end`;
                document.location.replace(params);
                return;
            }
        }
    }

    private chgSpecific(item: any) {
        if (item === null) {
            this.curForm = null;
            return;
        }
        if (this.specificProp && this.specificProp.length > 0) {
            for (const el of this.specificProp) {
                if (el.spf === item.spf) {
                    this.curForm = el;
                    const data: any = { year: this.curYear, yearProp: this.year, dataType: false, gr: false, abp: false, prg: false, ppr: null, gu: false, variant: false };

                    if (this.curDataType !== null) { data.dataType = this.curDataType.code; }
                    if (this.curFunGr !== null) { data.gr = this.curFunGr.gr; }
                    if (this.curAbp !== null) { data.abp = this.curAbp.abp; }
                    if (this.curProg !== null) { data.prg = this.curProg.prg; }
                    if (this.curSubProg !== null) { data.ppr = this.curSubProg.ppr; }
                    if (this.curComp !== null && this.curComp.code !== null) { data.gu = this.curComp.code; }
                    if (this.curBudgetVersion !== null) { data.variant = this.curBudgetVersion.variant_uuid }
                    const params = `#${item.link}/${data.year}/${data.yearProp}/${data.dataType}/${data.gr}/${data.abp}/${data.prg}/${data.ppr}/${data.gu}/${data.variant}/true/end`;
                    document.location.replace(params);
                    return;
                }
            }
        }
    }
}
